<template>
	<div :id="id" style="width: 450px;" class="ui search selection dropdown">
		<input type="hidden" name="event">
		<i class="dropdown icon"></i>
		<div class="default text">{{placeholder}}</div>
		<div class="menu">
			<slot></slot>
		</div>
	</div>
</template>

<script>
export default {
	name: 'search-dropdown',
	props: {
		placeholder: {default: () => 'Select'},
    v: {default: () => []},
    allowAdditions: {default: false}
  },
	data () {
    return {
      id: Math.random().toString(36).substr(2, 9),
      value: this.v
    }
  },
	watch: {
    value: function (v) {
      this.$emit('update:value', v)
      this.$emit('change', v)
    }
  },
	mounted () {
		let ctx = this
		this.$nextTick(() => {
			/* eslint-disable */
			$(`#${this.id}`).dropdown({
				'set selected': this.v,
				clearable: true,
				allowAdditions: this.allowAdditions,
				forceSelection: false,
				hideAdditions: false,
				onChange: function (value, text, $selectedItem) {
					if ($selectedItem) {
						/* eslint-disable */
						ctx.$emit('selected', $($selectedItem[0]).attr('data-value'))
					} else {
						ctx.$emit('selected', '')
					}	
				}
			})
		});
		/* eslint-disable */
		$(`#${this.id}`).dropdown({
			'set selected': this.v,
			clearable: true,
			allowAdditions: this.allowAdditions,
			forceSelection: false,
			hideAdditions: false,
			onChange: function (value, text, $selectedItem) {
					if ($selectedItem) {
						ctx.$emit('selected', $($selectedItem[0]).attr('data-value'))
					} else {
						ctx.$emit('selected', '')
					}
					
			}
		})
	}
}
</script>