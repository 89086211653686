<template>
	<div v-if="pages" class="wfill flex">
		<div style="margin: 0 auto;" class="mlauto ui pagination menu">
			<a @click="gotoPage(0)" v-if="page != 0" class="item">
				1
			</a>
			<div @click="gotoPage(page - 1)" v-if="page != 0" class="item">
				<i class="angle left icon" ></i>
			</div>
			<a @click="gotoPage(n - 1)"
					v-for="(n) in new Array(pages).fill(0).map((num, i) => i + 1).slice(page, page + 3)" :key="n" class="item">
				{{n}}
			</a>
			<div @click="gotoPage(page + 1)" v-if="pages > 4 && page < pages - 2" class="item">
				<div 
					style="margin: 0px; padding: 0; min-width: 0px;" class="item">
					<i class="angle right icon" ></i>
				</div>
			</div>
			<a @click="gotoPage(pages - 1)" v-if="pages > 4" class="item">
				{{pages}}
			</a>
		</div>
	</div>
</template>

<script>
export default {
	name: 'pagination',
	props: {
		pages: {default: 10}
	},
	data () {
		return {
			page: 0
		}
	},
	methods: {
		gotoPage: function (page) {
			this.page = page;
			this.$emit('gotoPage', page)
		}
	}
}
</script>